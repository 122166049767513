import React from "react";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import img from "./../../img/logofooter.png";
import s from "./Footer.module.scss";

function Footer() {
  return (
    <div className={s.Footer}>
      <Container>
        <Row>
          <Col md={6} className="m-auto">
            <div className={s.FooterLeft}>
              <img className={s.img} src={img} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
